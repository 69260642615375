<template>
  <LayoutView
    :titulo="`Detalle de asignación #${id}`"
    :fondo="asignacion && asignacion ? asignacion.local.imagen : ''"
    :seccionDetalle="`${estados[7].value}. Estado ${estados[7].title} `"
  >
    <!-- <p v-hide-supervisor>AAAA</p> -->
    <form-wizard
      ref="wizard"
      stepSize="sm"
      color="var(--dark)"
      error-color="var(--dark)"
      title=""
      subtitle=""
      back-button-text="Retroceder"
      next-button-text="Siguiente"
      finish-button-text="Actualizar Asignación"
      @on-complete="openModal"
    >
      <!-- INGRESAR TAREAS -->
      <tab-content
        title="Revisión de partidas"
        :before-change="() => validateStep('step1')"
      >
        <PrimerTab
          ref="step1"
          v-if="asignacion"
          @on-validate="mergeData"
        ></PrimerTab>
      </tab-content>
    </form-wizard>

    <b-modal
      id="modal-corroboracion"
      hide-footer
      center
      title="¿Desea confirma su acción?"
    >
      <p class="h4 text-center">
        Por favor confirme el estado de todas las partidas.
      </p>
      <p class="h6 font-italic text-center mt-3">
        Si alguna partida <code>DEBE ARREGLARSE</code> se notificará al
        <code>CONTRATISTA</code> para que vuelva a ejecutar estas partidas por
        arreglar.
      </p>
      <b-button
        :disabled="loading"
        @click="submitData"
        class="mt-3"
        block
        variant="success"
      >
        <template v-if="loading">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span class="sr-only">Confirmando...</span>

          <span class="d-inline-block ml-2">Confirmando</span>
        </template>

        <span v-else>Confirmar revisión de las partidas</span>
      </b-button>
    </b-modal>
  </LayoutView>
</template>

<script>
import { mapState } from "vuex";
import { estados } from "../../../../.env";
import GetAsignaciones from "@/apollo/queries/asignaciones/GetAsignaciones.gql";

import UpdateTareaAdministrador from "@/apollo/mutations/tareas/UpdateTareaAdministrador.gql";

import LayoutView from "@/layouts/detalles.vue";

import estadoMixins from "@/mixins/estadoMixins.js";
import baseReconocimiento from "@/mixins/baseReconocimiento.js";

import PrimerTab from "@/components/asignaciones/octavaVista/primerTab.vue";
import UpdateReconocimientoLocal from "@/apollo/mutations/asignaciones/UpdateReconocimientoLocal.gql";

import { hideAdmin, hideContratante } from "@/directives/roleDirective.js";

export default {
  components: { LayoutView, PrimerTab },
  directives: { hideAdmin, hideContratante },
  mixins: [estadoMixins, baseReconocimiento],
  data() {
    return {
      id: this.$route.params.id,
      estados: estados,
      loading: false,
      asignacion: null,
      tareas: [],
      input: null,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),
  },
  methods: {
    mergeData(input, isValid) {
      if (isValid) {
        this.input = { ...input };
      }
    },
    validateStep(name) {
      var refToValidate = this.$refs[name];
      return refToValidate.validate();
    },
    openModal() {
      this.$bvModal.show("modal-corroboracion");
    },

    privateUpdateEstadoTareas() {
      this.tareas = this.$refs.step1.tareas;

      const tareasFormatted = this.tareas.map(
        (t) =>
          (t = {
            tareaId: t.tareaId,
            estado: t.newState,
            precioAdministrador: t.precioAdministrador,
            precioContratista: t.precioContratista,
          })
      );

      return new Promise((resolveFunction) => {
        this.$apollo
          .mutate({
            mutation: UpdateTareaAdministrador,
            errorPolicy: "all",
            variables: {
              input: tareasFormatted,
            },
          })
          .then((resTarea) => {
            if (resTarea.errors) {
              this.loading = false;
              const h = this.$createElement;

              const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
                h("b-spinner", { props: { type: "grow", small: true } }),
                ` Los estados de las partidas no se han podido actualizar, intentelo nuevamente `,
                h("b-spinner", { props: { type: "grow", small: true } }),
              ]);
              // Create the title
              const vNodesTitle = h(
                "div",
                {
                  class: [
                    "d-flex",
                    "flex-grow-1",
                    "align-items-baseline",
                    "mr-2",
                  ],
                },
                [h("strong", { class: "mr-2" }, "Estados no actualizados")]
              );

              this.$bvToast.toast([vNodesMsg], {
                title: [vNodesTitle],
                solid: true,
                variant: "danger",
                time: 5000,
              });
              return;
            }
            resolveFunction();
          });
      });
    },

    submitData() {
      this.loading = true;

      // SI ENCUENTRA UNA TAREA por corregir estará observado
      // IF isOneObserved then ESTADO de Asignacion SERÄ a REVISAR, SINO será APROBADO

      // isOneObserve == true - Quiere decir que al menos una partida se debe corregir
      let isOneObserved =
        this.$refs.step1.tareas.filter((t) => t.newState === 3).length > 0;

      this.privateUpdateEstadoTareas().then(async () => {
        //

        if (isOneObserved) {
          this.asignacion.fechaInicioVigente = null;
        } else {
          this.asignacion.fechaInicioVigente = this.input.fechaInicioVigente;
          this.asignacion.tiempoEntrega = this.input.deliveryDays;
        }

        this.updateReconocimientoLocal(this.asignacion, this.id).then(
          async (res) => {
            if (res.errors) {
              this.loading = false;

              const h = this.$createElement;

              const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
                h("b-spinner", { props: { type: "grow", small: true } }),
                ` La asignación no se ha podido actualizar, intentelo nuevamente `,
                h("b-spinner", { props: { type: "grow", small: true } }),
              ]);
              // Create the title
              const vNodesTitle = h(
                "div",
                {
                  class: [
                    "d-flex",
                    "flex-grow-1",
                    "align-items-baseline",
                    "mr-2",
                  ],
                },
                [h("strong", { class: "mr-2" }, "Asignación no actualizada")]
              );

              this.$bvToast.toast([vNodesMsg], {
                title: [vNodesTitle],
                solid: true,
                variant: "danger",
              });
            } else {
              this.loading = false;
              await this.privateActualizarEstadoAsignacion(
                isOneObserved ? 9 : 10
              );

              const h = this.$createElement;

              const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
                ` Partidas actualizadas como EJECUTADAS, queda pendiente la revisión del supervisor `,
              ]);
              // Create the title
              const vNodesTitle = h(
                "div",
                {
                  class: [
                    "d-flex",
                    "flex-grow-1",
                    "align-items-baseline",
                    "mr-2",
                  ],
                },
                [h("strong", { class: "mr-2" }, "Partidas Ejecutadas")]
              );

              this.$bvToast.toast([vNodesMsg], {
                title: [vNodesTitle],
                solid: true,
                variant: "success",
                time: 5000,
              });

              if (isOneObserved) {
                this.$router.push({
                  name: "asignaciones",
                });
              } else {
                this.$router.push({
                  name: "asignacion-detalle-noveno",
                  params: { id: this.id },
                });
              }
            }
          }
        );

        //
      });
    },

    init() {
      this.$apollo
        .query({
          query: GetAsignaciones,
          variables: {
            codigoTicket: this.id,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          this.asignacion = response.data.GetAsignaciones;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.galeria-previa {
  &__imagen {
    width: 100%;
    margin: 0 auto;
    border-radius: 0.3rem;
  }

  &__icono {
    font-size: 25px;
    color: red;

    background-color: white;
    padding: 10px;
    border-radius: 50%;
    -webkit-box-shadow: -16px 22px 56px -18px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -16px 22px 56px -18px rgba(0, 0, 0, 0.75);
    box-shadow: -16px 22px 56px -18px rgba(0, 0, 0, 0.75);
    position: absolute;
    top: 0;
    right: 0;
  }

  textarea {
    height: 60px !important;
  }
}

@media (min-width: 768px) {
  .galeria-previa {
    &__icono {
      margin: 20px 20px 0 0;
      right: 1rem;
    }
  }
}
</style>
