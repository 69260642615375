<template>
  <div class="row" v-if="asignacion">
    <div class="col">
      <!-- Si ya existe reconocimiento de local -->
      <!-- DEBE EXISTIR TAREAS OBVIAMENTE PARA ESTA PARTE -->
      <template>
        <div class="d-flex justify-content-between align-items-center">
          <p class="h5 detalles-asignacion__sub font-italic my-0">
            Partidas registradas
          </p>
        </div>

        <hr />

        <p class="small mt-1 text-center font-italic" v-if="loading">
          <template>
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only"></span>

            <span class="d-inline-block ml-2">Obteniendo Partidas</span>
          </template>
        </p>

        <!-- MUESTRA DE TAREAS ASIGNADAS -->
        <section class="mt-3" v-else>
          <div class="row" v-if="tareas.length">
            <div
              class="col-md-12 mb-3"
              v-for="tarea in tareas"
              :key="tarea.tareaId"
            >
              <!-- Muestra un card con las tareas, cada card es collapse y muestra info detallada de cada tarea -->
              <b-card class="bg-dark">
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <a
                      class="text-white"
                      href=""
                      v-b-toggle="tarea.tareaId"
                      @click.prevent
                      variant="info"
                      >{{ tarea.descripcion }}</a
                    >
                  </div>
                </div>

                <b-collapse :id="tarea.tareaId" class="mt-2">
                  <hr />

                  <b-card>
                    <p>
                      <i class="fas fa-calendar-alt"></i>
                      {{ tarea.created_at | moment("DD/MM/YYYY") }}
                    </p>

                    <small class="text-muted">Observaciones:</small>

                    <p class="card-text my-1 pb-1 border-bottom">
                      {{ tarea.observacion }}
                    </p>

                    <div class="row mt-2">
                      <div class="col-md-12">
                        <p class="my-1 pb-1 border-bottom">
                          <span class="small text-muted">Descripción</span>
                          <span class="ml-2">
                            {{ tarea.descripcion }}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <!-- <div class="col-md-4 col-lg-4">
                        <p class="my-1 pb-1 border-bottom">
                          <span class="small text-muted">Categoría:</span>
                          <span class="ml-2">{{
                            tarea.categoria.descripcion
                          }}</span>
                        </p>
                      </div>

                      <div class="col-md-4 col-lg-4">
                        <p class="my-1 pb-1 border-bottom">
                          <span class="small text-muted">Sub categoría:</span>
                          <span class="ml-2">{{
                            tarea.SubCategoria.descripcion
                          }}</span>
                        </p>
                      </div> -->

                      <div class="col-md-12">
                        <p class="my-1 pb-1 border-bottom">
                          <span class="small text-muted"
                            >Horario asignado:</span
                          >
                          <span class="ml-2">{{
                            tarea.horario === 0 ? "Diurno" : "Nocturno"
                          }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-md-4">
                        <p class="mt-3 mb-1 pb-1 border-bottom">
                          <span class="small text-muted">Medidas:</span>
                          <span class="ml-2"
                            >{{ tarea.cantidad }} ({{
                              tarea.unidadMedida
                            }})</span
                          >
                        </p>
                      </div>
                      <div class="col-12 col-md-4">
                        <div class="mt-3 mb-1 pb-1 border-bottom">
                          <span class="small text-muted"
                            >Estado actual de la Tarea:</span
                          >
                          <b-badge
                            class="px-4 py-2 ml-3"
                            pill
                            :variant="getEstadoTarea(tarea)[1]"
                            >{{ getEstadoTarea(tarea)[0] }}</b-badge
                          >
                        </div>
                      </div>

                      <div class="col-12 col-md-4">
                        <div class="mt-3 mb-1 pb-1 border-bottom">
                          <span class="small text-muted"
                            >Revisión de la Tarea:</span
                          >
                          <v-select
                            :options="estadosDisponible"
                            label="title"
                            :clearable="false"
                            :reduce="(e) => e.value"
                            v-model="tarea.newState"
                          >
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </section>

        <!-- INICIO TAREA VIGENTE -->
        <section class="mt-5" v-if="!isOneObserved">
          <hr />
          <div class="row">
            <div class="col-12">
              <p class="h3 mt-3 font-italic font-weight-bold text-center">
                Ingreso de Fechas
              </p>
            </div>
            <div class="col-12 mt-3">
              <div class="form-row">
                <b-input-group
                  append="dias hábiles"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    size="lg"
                    v-model="deliveryDays"
                    placeholder="Digite cuantos dias tomaría realizar la entrega"
                    @keypress="regexNumberInt"
                    type="number"
                  ></b-form-input>
                </b-input-group>
                <p>
                  El tiempo de entrega que se generará será de
                  <span class="font-weight-bold mt-2 font-italic">
                    {{ deliveryDays }} dias hábiles</span
                  >
                </p>
              </div>
            </div>
            <div class="col-12 mt-3 mb-3">
              <div class="form-row">
                <b-input-group
                  append="Fecha de Inicio Vigente"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-datepicker
                    size="lg"
                    id="example-datepicker"
                    placeholder="Indica la fecha de inicio vigente"
                    v-model="fechaInicioVigente"
                  ></b-form-datepicker>
                </b-input-group>
              </div>
            </div>
          </div>
        </section>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { estadosTareas } from "../../../../.env.js";
import { precio } from "@/mixins/precio";
import regexMixin from "@/mixins/regexMixins";
import {
  hideAdmin,
  hideContratante,
  hideSupervisor,
} from "@/directives/roleDirective.js";

import GetAllTareas from "@/apollo/queries/tareas/GetAllTareas.gql";
import GetAsignaciones from "@/apollo/queries/asignaciones/GetAsignaciones.gql";
import { validationMixin } from "vuelidate";
const { required, requiredIf } = require("vuelidate/lib/validators");

export default {
  directives: { hideAdmin, hideContratante, hideSupervisor },

  mixins: [precio, regexMixin, validationMixin],
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      tareas: [],
      asignacion: null,
      fechaInicioVigente: null,
      deliveryDays: null,
      estadosTareas: estadosTareas,
      estadosDisponible: [
        {
          title: "Se debe corregir",
          value: 3,
          variant: "warning",
        },
        {
          title: "Aprobada",
          value: 4,
          variant: "success",
        },
      ],
    };
  },
  validations: {
    deliveryDays: {
      required: requiredIf(function() {
        return !this.isOneObserved;
      }),
    },

    fechaInicioVigente: {
      required: requiredIf(function() {
        return !this.isOneObserved;
      }),
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),
    isOneObserved() {
      return this.tareas.filter((t) => t.newState === 3).length > 0;
    },
  },
  methods: {
    validate() {
      this.$v.$touch();
      var isValid = !this.$v.$invalid;

      if (!isValid) {
        const h = this.$createElement;

        const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
          h("b-spinner", { props: { type: "grow", small: true } }),
          ` Debe ingresar la fecha de inicio vigente y dias habiles.`,
          h("b-spinner", { props: { type: "grow", small: true } }),
        ]);
        // Create the title
        const vNodesTitle = h(
          "div",
          {
            class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"],
          },
          [h("strong", { class: "mr-2" }, "Debe llenar la información")]
        );

        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          solid: true,
          variant: "danger",
        });
      } else {
        const input = {
          deliveryDays: this.deliveryDays,
          fechaInicioVigente: this.fechaInicioVigente,
        };

        this.$emit("on-validate", input, isValid);
      }

      return isValid;
    },
    getEstadoTarea(item) {
      let estado = this.estadosTareas.find((e) => e.value == item.estado);

      return [estado["title"], estado["variant"]];
    },
    setSubtotal(precio, cantidad) {
      return parseFloat(precio) * parseInt(cantidad);
    },

    init() {
      return new Promise((resolve) => {
        this.getInfo().then(() => {
          this.getTareas();

          resolve();
        });
      });
    },
    getInfo() {
      return new Promise((resolve) => {
        this.$apollo
          .query({
            query: GetAsignaciones,
            variables: {
              codigoTicket: this.id,
            },
            fetchPolicy: "no-cache",
          })
          .then((response) => {
            this.asignacion = response.data.GetAsignaciones;
            this.deliveryDays = this.asignacion.tiempoEntrega;
            this.fechaInicioVigente = this.asignacion.fechaInicioVigente;
            resolve();
          });
      });
    },

    getTareas() {
      this.loading = true;

      this.$apollo
        .query({
          query: GetAllTareas,
          variables: {
            numberPaginate: 100,
            page: 1,
            codigoTicket: this.id,
            estado: "",
          },
          errorPolicy: "all",
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          this.tareas = res.data.GetAllTareas.data;

          this.tareas = this.tareas.map(
            (t) =>
              (t = {
                ...t,
                newState: 4,
              })
          );

          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 15px;
}
</style>
